import React from 'react'
import { graphql } from 'gatsby'
import Marco from '../components/marco'
import Container from '@material-ui/core/Container'
import Comentarios from '../components/comentarios'
import Minipaginas from '../components/minipaginas'
import Minipagina from '../components/minipagina'
import FotoConLeyenda from '../components/foto-con-leyenda'
import TextoEnMinipagina from '../components/texto-en-minipagina'
import { Tips } from '../components/estilos/estilos-de-articulo'
import { css } from '@emotion/core'
import EspaciadorVertical from '../components/espaciador-vertical'
import Navegar from '../components/navegar'

const urllink = css`
  & a,
  & a:active,
  & a:visited,
  & a:any-link {
    color: white;
    font-size: 14px;
  }
`

// #region GraphQL
export const query = graphql`
  {
    allTipsLicenciaYaml {
      edges {
        node {
          nombre
          origen {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
// #endregion

const TipsLicencia = ({ data, location }) => {
  // eslint-disable-next-line no-unused-vars
  const toFluid = nombre => {
    const edges = data.allTipsLicenciaYaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fluid = found.node.origen.childImageSharp.fluid
    return fluid
  }
  return (
    <Marco>
      <Tips>
        <Container maxWidth="xl">
          <Minipaginas>
            <Minipagina>
              <FotoConLeyenda
                fluid={toFluid('imgblogtlicenciaLicenciadeconducirinternacionaljpg')}
              />
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h2>Licencia de conducir internacional</h2>
                <p>
                  ¿Necesito la licencia de conducir internacional en Italia? Sí. Si uno lee en las
                  fuentes autorizadas, por ejemplo, consulado de Italia, empresas de alquiler de
                  autos, se ve que sí se necesita.
                </p>
                <p>
                  No obstante, dado la inistencia de tantas personas que me decían que no se
                  necesita, decidí hacer una prueba, cuando fui a alquilar el auto presenté sólo la
                  licencia argentina y ¿qué pasó? me pidieron también la internacional. Lo cual era
                  absolutamente lógico, porque la nuestra estaba sólo en español, ellos necesitan
                  algo escrito en inglés (no la tapa, como se ve en la foto que está en español). Si
                  bien ahora nuestras licencias están también en inglés, no obstante se requiere
                  tener la internacional por una norma internacional. No creo que la nueva licencia
                  reemplace a la internacional. Tal vez algunas empresas de alquiler de autos no te
                  la pidan pero si llegaras a tener cualquier inconveniente te la van a pedir. Es un
                  riesgo no tenerla. Yo prefiero no correr ningún riesgo ni pasar malos momentos.
                </p>
                <p>
                  Ojo también con un detalle, se necesitan las dos licencias, la argentina y la
                  internacional. Y controla que no estén vencidas, no solamente cuando vas a iniciar
                  el viaje, sino ¡que sigan vigentes durante el viaje!
                </p>
                <h3>¿Cómo obtengo la licencia de conducir internacional?</h3>
                <p>
                  Cerca de la fecha del viaje voy al Automóvil Club de Libertador y hago el trámite.
                  Te lo dan en el momento. Tiene vigencia de un año. Te paso el link:
                </p>
                <p css={urllink}>
                  <a href="http://www.aca.org.ar/registro-internacional/">
                    http://www.aca.org.ar/registro-internacional/
                  </a>
                </p>
                <h3> Ventaja de tener la licencia internacional. </h3>
                <p>
                  Sirve como documento. O sea, cuando alquilo por ejemplo un «audioguida» en los
                  museos, para escuchar las explicaciones, me piden un documento y como el pasaporte
                  no lo llevo conmigo por motivos de seguridad, entonces el único documento que me
                  sirve es la licencia de conducir. Que en italiano se llama «patente». Tan
                  conveniente es tenerla que en mi próximo viaje si bien no voy alquilar un auto,
                  estoy pensando en renovarla de todos modos para usarla como documento.
                </p>
              </TextoEnMinipagina>
            </Minipagina>
          </Minipaginas>
          <Navegar location={location} />
          <Comentarios />
          <EspaciadorVertical altura={36} />
        </Container>
      </Tips>
    </Marco>
  )
}
export default TipsLicencia
